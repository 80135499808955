.custom-notification {
	color: #b4bed2;
	background-color: #141a21cc;
	border: 1px solid #364e8c64;
	box-shadow: 0 0 12px #364e8c3c;
}

.ant-notification-notice .ant-notification-notice-message {
	color: #b4bed2;
	font-weight: 700;
}

.ant-notification-notice .ant-notification-close-icon {
	color: #ff0000;
}
