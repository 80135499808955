:root {
	--primary-dark-bg: #141a21;
	--primary-light-bg: #fafafb;
	--secondary-light-bg: #f6f7f8;
	--secondary-dark-bg: #1c252e;
	--default-border: #353d4675;
	--default-border-light: #e6ebf1;
}

/*Standard styles for scroll bar*/
::-webkit-scrollbar {
	width: 8px !important;
	height: 8px !important;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.244) !important;
	border-radius: 4px !important;
	visibility: hidden !important;
	transition: visibility 0.3s !important;
}

:hover::-webkit-scrollbar-thumb {
	visibility: visible !important;
}

::-webkit-scrollbar-thumb:hover {
	visibility: visible !important;
	background-color: rgba(0, 0, 0, 0.323) !important;
}

/*Table search bar height*/
.ant-input-search .ant-input-search-button {
	height: 39.6px !important;
}

/*Inner layout padding override*/
.css-8d0old {
	margin-top: 66px !important;
	padding: 15px 20px 0px !important;
}

/*footer styles*/
.css-b5un9s-Footer {
	border-top: 2px solid #b4bed22c !important;
}

/*Customized Avatar styles*/
.custom-avatar-dark .ant-avatar-image {
	background-color: #414b56 !important;
	background: #414b56 !important;
	border: 2px solid #a8bcee80 !important;
}

.custom-avatar-light .ant-avatar-image {
	background-color: #fafafb !important;
	background: #ebebee !important;
	border: 2px solid #dadada !important;
}

.custom-avatar-light .avatar-status-name {
	color: #455560 !important;
	font-weight: 800 !important;
	font-size: 12px !important;
}

.custom-avatar-dark .avatar-status-name {
	font-weight: 800 !important;
	font-size: 12px !important;
	color: #b4bed2 !important;
}

/*Default divider Color*/
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
	border-block-start-color: #b4bed22c;
}
.ant-divider {
	border-block-start-color: #b4bed22c;
}

/*Default wizard divider Color*/
.ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: rgba(180, 190, 210, 0.25);
}

/*Default Back button styles*/
.back {
	font-size: 24px !important;
	color: #e4002b !important;
	margin-right: 10px !important;
}

/*Default Tab line color*/
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
	border-bottom: 2px solid #b4bed22c;
}

/*Default menu styles*/
:where(.css-dev-only-do-not-override-d8qnjx).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-d8qnjx).ant-menu-light.ant-menu-root.ant-menu-vertical {
	border-inline-end: none !important;
}

:where(.css-dev-only-do-not-override-tdlzjd).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-tdlzjd).ant-menu-light.ant-menu-root.ant-menu-vertical {
	border-inline-end: 1px solid var(--secondary-dark-bg) !important;
}

/*Menu group title default */
.ant-menu-light .ant-menu-item-group-title {
	color: #b4bed2 !important;
}

/*Drawer default styes*/
.ant-drawer .ant-drawer-header {
	border-bottom: 2px solid #b4bed22c;
}
.ant-drawer .ant-drawer-footer {
	border-top: 2px solid #b4bed22c;
}

/*Error styles*/
.ant-form-item .ant-form-item-explain-error {
	font-size: xx-small;
}

/*Default table border dark*/
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
	border-inline-end: 1px solid var(--default-border) !important;
}
.table-border-dark.ant-table-wrapper.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-footer {
	border: 1px solid var(--default-border);
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
	text-transform: uppercase;
	font-size: 12px;
}

.table-border-dark.ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr:not(:last-child)
	> th,
.table-border-dark.ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> thead
	> tr:not(:last-child)
	> th,
.table-border-dark.ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> thead
	> tr:not(:last-child)
	> th,
.table-border-dark.ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> thead
	> tr:not(:last-child)
	> th {
	border-bottom: 1px solid var(--default-border);
}

.table-border-dark.ant-table-wrapper .ant-table-tbody > tr > th,
.table-border-dark.ant-table-wrapper .ant-table-tbody > tr > td {
	border-bottom: 1px solid var(--default-border) !important;
}

.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
	border-top: 1px solid var(--default-border) !important;
}

.table-border-dark.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
	border-inline-start: 1px solid var(--default-border) !important;
}

.table-border-dark.ant-table-wrapper .ant-table-thead > tr > th,
.table-border-dark.ant-table-wrapper .ant-table-thead > tr > td {
	border-bottom: 1px solid var(--default-border) !important;
}

/*Default table border light*/
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
	border-inline-end: 1px solid #b4bed25f !important;
}
.table-border-light.ant-table-wrapper.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-footer {
	border: 1px solid #b4bed25f;
}

.table-border-light.ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr:not(:last-child)
	> th,
.table-border-light.ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> thead
	> tr:not(:last-child)
	> th,
.table-border-light.ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> thead
	> tr:not(:last-child)
	> th,
.table-border-light.ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> thead
	> tr:not(:last-child)
	> th {
	border-bottom: 1px solid #b4bed25f;
}

.table-border-light.ant-table-wrapper .ant-table-tbody > tr > th,
.table-border-light.ant-table-wrapper .ant-table-tbody > tr > td {
	border-bottom: 1px solid #b4bed25f !important;
}

.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
	border-top: 1px solid #b4bed25f !important;
}

.table-border-light.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
	border-inline-start: 1px solid #b4bed25f !important;
}

.table-border-light.ant-table-wrapper .ant-table-thead > tr > th,
.table-border-light.ant-table-wrapper .ant-table-thead > tr > td {
	border-bottom: 1px solid #b4bed25f !important;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-title,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-footer,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table-wrapper .ant-table.ant-table-small tfoot > tr > td {
	padding: 5px 5px !important;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
	margin: 5px 0;
}

/*React Quill*/
.ql-toolbar.ql-snow {
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}

.ql-container {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.ql-editor.ql-blank::before {
	color: #b4bed22c;
}
.ql-editor {
	min-height: 150px;
	max-height: 1000px;
	overflow-y: auto;
}

.rich-text-editor-dark .ql-snow .ql-picker-options {
	background-color: var(--primary-dark-bg);
}

.rich-text-editor-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	border-color: var(--default-border) !important;
	border-radius: 8px;
}

.rich-text-editor-light .ql-snow .ql-picker-options {
	background-color: var(--primary-light-bg);
}

.rich-text-editor-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	border-color: var(--default-border-light) !important;
	border-radius: 8px;
}

.rich-text-editor-light .ql-container.ql-snow,
.ql-snow .ql-tooltip {
	border: 1px solid var(--default-border-light);
}

.rich-text-editor-dark .ql-container.ql-snow,
.ql-snow .ql-tooltip {
	border: 1px solid var(--default-border);
}

.rich-text-editor-light .ql-toolbar.ql-snow {
	border: 1px solid var(--default-border-light);
}

.rich-text-editor-dark .ql-toolbar.ql-snow {
	border: 1px solid var(--default-border);
}

.rich-text-editor-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	border-color: var(--default-border-light);
}

.rich-text-editor-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	border-color: var(--default-border);
}

/*Side Menu*/
.custom-menu.ant-menu .ant-menu-item .ant-menu-item-icon,
.custom-menu.ant-menu .ant-menu-submenu-title .ant-menu-item-icon,
.custom-menu.ant-menu .ant-menu-item .anticon,
.custom-menu.ant-menu .ant-menu-submenu-title .anticon {
	font-size: 18px;
	padding-right: 4px;
	color: #72849ae6;
}

.ant-menu .ant-menu-item-divider {
	border-color: var(--default-border) !important;
}

.ant-menu-light .ant-menu-item-group-title {
	color: rgba(180, 190, 210, 0.45) !important;
}
